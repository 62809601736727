<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click.prevent.stop="onDismissModal()">
            <div class="andr_btn">
              <!-- <ion-icon name="arrow-back-outline" ></ion-icon> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                class="ionicon"
                viewBox="0 0 512 512"
              >
                <title>Arrow Back</title>
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="48"
                  d="M244 400L100 256l144-144M120 256h292"
                />
              </svg>
            </div>
            <div class="ios_btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.438"
                height="31.438"
                class="ionicon"
                viewBox="0 0 512 512"
              >
                <title>Chevron Back</title>
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="48"
                  d="M328 112L184 256l144 144"
                />
              </svg>
            </div>
          </ion-button>
        </ion-buttons>
        <ion-title>이미지 상세보기</ion-title>
        <!-- <ion-buttons slot="end">
          <ion-button @click.prevent.stop="onDismissModal()">닫기</ion-button>
        </ion-buttons> -->
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="false">
      <div class="img_details" v-if="items.length">
        <div v-if="1 < items.length">
          <div
            class="swiper-button-prev"
            v-if="state.isPrevBtn"
            @click.prevent.stop="slidePrev()"
          ></div>
          <div
            class="swiper-button-next"
            v-if="state.isNextBtn"
            @click.prevent.stop="slideNext()"
          ></div>
        </div>
        <!-- :disabled="disableNexttBtn" :disabled="disablePrevBtn" -->
        <!-- 축제, 커뮤니티 상세보기
          버튼 방법 https://youtu.be/-Qm-tG4Kt9s
        -->
        <div class="img_details_slide" v-if="state.isShow">
          <ion-slides
            pager="true"
            :options="{ slidesPerView: 1, initialSlide: position, lazy: true }"
            ref="myslied"
            @ionSlideDidChange="slideChanged"
            :key="`${items.length}`"
          >
            <ion-slide v-for="(item, index) in items" :key="`items_${index}`">
              <div class="detail_box">
                <img :src="`${item.url}${item.path}`" />
              </div>
            </ion-slide>
          </ion-slides>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped lang="scss">
@import "./ImageDetail.scss";
</style>
<script>
// IonImg
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonSlides,
  IonSlide,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Analytics } from "../common/analytics";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonSlides,
    IonSlide,
  },
  props: {
    items: {
      type: Array,
      default: null,
      description: "(default -> item)",
    },
    position: {
      type: Number,
      default: 0,
      description: "(default -> 0)",
    },
    screenName: {
      type: String,
      default: null,
      description: "(default -> 0)",
    },
  },

  setup(props) {
    const analytics = new Analytics();
    const state = reactive({
      isShow: false,
      isPrevBtn: true,
      isNextBtn: true,
    });

    const myslied = ref(null);

    const slideOpts = {
      // Demo Autoheight
      autoHeight: true,
      //이미지 높이 자동으로 설정
      initialSlide: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      speed: 400,
    };

    const slideChanged = async (event) => {
      let position = props.position;
      if (event) {
        await event.target.getActiveIndex().then((i) => {
          position = i;
        });
      }

      if (position == 0) {
        state.isPrevBtn = false;
      } else if (position < props.items.length - 1) {
        state.isNextBtn = true;
        state.isPrevBtn = true;
      } else {
        state.isNextBtn = false;
        state.isPrevBtn = true;
      }
    };

    const slidePrev = async () => {
      const s = await myslied?.value?.$el.getSwiper();
      await s.slidePrev();
    };

    const slideNext = async () => {
      const s = await myslied?.value?.$el.getSwiper();
      await s.slideNext();
    };

    const onDismissModal = async () => {
      modalController.dismiss();
    };

    // onMounted
    onMounted(() => {
      console.log("onMounted");
      setTimeout(function () {
        state.isShow = true;
      }, 300);
      slideChanged();
      analytics.setScreenName(props.screenName);
    });

    return {
      slideOpts,
      onDismissModal,
      myslied,
      slidePrev,
      slideNext,
      state,
      slideChanged,
    };
  },
});
</script>
