
import { Browser } from "@capacitor/browser";
import {
  IonSlides,
  IonSlide,
  IonPage,
  IonContent,
  IonSpinner,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import Empty from "../../../components/Empty.vue";
import ImageDetail from "../../../components/ImageDetail.vue";
import { Analytics } from "../../../common/analytics";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
    IonPage,
    IonContent,
    Empty,
    IonSpinner,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonRefresher,
    IonRefresherContent,
    IonImg,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const screenName = `PAGE-FESTIVAL_${props.id}`;
    const analytics = new Analytics();
    const state = reactive({
      festival: {
        loading: true,
        data: {
          id: 0,
          // text:"",
          file: [],
        },
      },
      contentLoading: true,
      text: "",
    });

    const slideOpts = {
      slidesPerView: "auto",
      speed: 400,
      pagination: false,
    };

    const onOpenUrl = async (url: string) => {
      if (url.length) {
        await Browser.open({ url: url });
      }
    };

    const fetch = async (event: any = null) => {
      await services.festivalOne(props.id).then(
        (response) => {
          const { data } = response;

          if (new Date().getTime() < new Date(data.startDate).getTime()) {
            // 예정
            data.status = "예정";
          } else if (new Date(data.endDate).getTime() < new Date().getTime()) {
            // 종료
            data.status = "종료";
          } else {
            // 진행중
            data.status = "진행 중";
          }

          state.festival.data = data;
          state.text = data.text.replace(/\n/g, "<br />");
          state.festival.loading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.festival.data.id = 0;
            }
          }
          state.festival.loading = false;
        }
      );
      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const onImageDetail = async (position = 0) => {
      const items: object[] = [];
      state.festival.data.file.some((item: object) => {
        items.push(item);
      });

      const modal = await modalController.create({
        component: ImageDetail,
        componentProps: {
          items: items,
          position: position,
          screenName: `MODAL-IMAGE-PAGE-FESTIVAL_${props.id}`,
        },
      });
      return modal.present();
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.festival.loading = true;
      fetch(event);
    };

    // onMounted
    onMounted(() => {
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return { slideOpts, state, onRefresh, onOpenUrl, onImageDetail };
  },
});
